import { useCallback, useEffect, useRef, useState } from "react";
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as yup from "yup";
import { Alert, Button, Container } from "@mui/material";
import ReCAPTCHA from "react-google-recaptcha";
import "./SignUp.scss";
import useAuth from "../../auth/useAuth";
import { HashLink } from "react-router-hash-link";

export const SignUp = () => {
  const auth = useAuth();
  const [outcome, setOutcome] = useState(null); // FIXME this should be RegisterOutcomeEnum but getting some errors,
                                                // even when trying to use <string>
  const [failed, setFailed] = useState(false);
  const [captcha, setCaptcha] = useState("");
  const [erCaptcha, setErCaptcha] = useState(false);
  const reCaptchaRef = useRef();
  let referralCode;
  const cookies = document.cookie;
  const refCode = cookies.split(";")[0].split("=");
  if (refCode[0] === "referral_code") {
    referralCode = refCode[1];
  }

  const handleCaptchaChange = useCallback(
    (e) => {
      setCaptcha(e);
    },
    [setCaptcha],
  );

  useEffect(() => {
  }, []);

  const submitForm = (values) => {
    setOutcome(null);
    setFailed(false);
    setErCaptcha(false);
    if (captcha) {
      auth
        .register({
          affiliate: {
            ...values,
            referralCode: referralCode,
          },
          gRecaptchaResponse: captcha,
        })
        .then((response) => {
          setOutcome(response["outcome"]);
        }).catch((err) => {
        setOutcome(err.response?.data?.outcome);
        setFailed(true);
        reCaptchaRef.current.reset();
      });
    } else {
      setErCaptcha(true);
    }
  };

  return (
    <Container className={"center-block"} maxWidth="sm">
      <h1 className="sign-in-title">Sign Up</h1>
      {failed && (
        <>
          {outcome === "EMAIL_ALREADY_USED" && (
            <Alert severity="warning">
              This email address is already used
            </Alert>
          )}
          {outcome === "FORM_ERROR" && (
            <Alert severity="error">
              Please check if the form is filled out correctly
            </Alert>
          )}
          {(outcome !== "FORM_ERROR" && outcome !== "EMAIL_ALREADY_USED") && (
            <Alert severity="error">
              There was an error, pleas try again later or <HashLink to="contact">contact us</HashLink>
            </Alert>
          )}
        </>
      )}
      {outcome === "SUCCESS" ? (
        <Alert severity="success">
          Registration Successful - Please check your email for further information
        </Alert>
      ) : (
        <Formik
          initialValues={{
            email: "",
            password: "",
            firstName: "",
            lastName: "",
            confirmPassword: "",
            companyName: "",
            phoneNumber: "",
            teams: "",
            telegram: "",
            website: "",
            trafficSourcesMethodList: [],
            trafficSourcesGeoList: [],
            estimatedMonthlyFtdsRange: "ZERO_TO_FIFTY",
            proposedDealType: "REV_SHARE",
            message: "",
          }}
          validationSchema={yup.object().shape({
            firstName: yup
              .string()
              .min(3, "First Name must be at least 3 characters")
              .required("First Name is required"),
            lastName: yup
              .string()
              .min(2, "Last Name must be at least 2 characters"),
            email: yup
              .string()
              .email("Email is invalid")
              .required("Email is required"),
            password: yup
              .string()
              .min(8, "Password must be at least 8 characters")
              .required("Password is required"),
            confirmPassword: yup
              .string()
              .oneOf([yup.ref("password"), null], "Passwords must match")
              .required("Confirm Password is required"),
            companyName: yup
              .string()
              .min(3, "Company name must be at least 3 characters")
              .required("Company name is required"),
            phoneNumber: yup
              .string()
              .min(10, "Phone number must be at least 10 characters"),
            teams: yup
              .string()
              .min(3, "Teams must be at least 3 characters")
              .required("Teams is required"),
            telegram: yup
                .string()
                .min(5, "Telegram must be at least 5 characters"),
            website: yup
              .string(),
            trafficSourcesMethodList: yup
              .array()
              .min(1, "Traffic sources method must be at least 1 selection")
              .required("Traffic sources method is required"),
            trafficSourcesGeoList: yup
              .array()
              .min(1, "Traffic sources geo must be at least 1 selection")
              .required("Traffic sources geo is required"),
            /* recaptcha: yup.string().required("ReCaptcha is required"), */
          })}
          onSubmit={submitForm}
        >
          {({ values, errors, touched, isSubmitting }) => (
              <Form className="signup-form">
                <div className="form-group">
                  <label htmlFor="firstName">First Name</label><span className="required-field">*</span>
                  <Field
                      name="firstName"
                      type="text"
                      className={
                          "form-control" +
                          (errors.firstName && touched.firstName
                              ? " is-invalid"
                              : "")
                      }
                  />
                  <ErrorMessage
                      name="firstName"
                      component="div"
                      className="invalid-feedback"
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="lastName">Last Name</label>
                  <Field
                      name="lastName"
                      type="text"
                      className={
                          "form-control" +
                          (errors.lastName && touched.lastName ? " is-invalid" : "")
                      }
                  />
                  <ErrorMessage
                      name="lastName"
                      component="div"
                      className="invalid-feedback"
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="email">Email</label><span className="required-field">*</span>
                  <Field
                      name="email"
                      type="text"
                      className={
                          "form-control" +
                          (errors.email && touched.email ? " is-invalid" : "")
                      }
                  />
                  <ErrorMessage
                      name="email"
                      component="div"
                      className="invalid-feedback"
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="password">Password</label><span className="required-field">*</span>
                  <Field
                      name="password"
                      type="password"
                      className={
                          "form-control" +
                          (errors.password && touched.password ? " is-invalid" : "")
                      }
                  />
                  <ErrorMessage
                      name="password"
                      component="div"
                      className="invalid-feedback"
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="confirmPassword">Confirm Password</label><span className="required-field">*</span>
                  <Field
                      name="confirmPassword"
                      type="password"
                      className={
                          "form-control" +
                          (errors.confirmPassword && touched.confirmPassword
                              ? " is-invalid"
                              : "")
                      }
                  />
                  <ErrorMessage
                      name="confirmPassword"
                      component="div"
                      className="invalid-feedback"
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="companyName">Company Name</label><span className="required-field">*</span>
                  <Field
                      name="companyName"
                      type="text"
                      className={
                          "form-control" +
                          (errors.companyName && touched.companyName
                              ? " is-invalid"
                              : "")
                      }
                  />
                  <ErrorMessage
                      name="companyName"
                      component="div"
                      className="invalid-feedback"
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="phoneNumber">Phone number</label><span className="required-field">*</span>
                  <Field
                      name="phoneNumber"
                      type="text"
                      className={
                          "form-control" +
                          (errors.phoneNumber && touched.phoneNumber
                              ? " is-invalid"
                              : "")
                      }
                  />
                  <ErrorMessage
                      name="phoneNumber"
                      component="div"
                      className="invalid-feedback"
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="teams">Teams</label><span className="required-field">*</span>
                  <Field
                      name="teams"
                      type="text"
                      className={
                          "form-control" +
                          (errors.teams && touched.teams ? " is-invalid" : "")
                      }
                  />
                  <ErrorMessage
                      name="teams"
                      component="div"
                      className="invalid-feedback"
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="telegram">Telegram</label>
                  <Field
                      name="telegram"
                      type="text"
                      className={
                          "form-control" +
                          (errors.telegram && touched.telegram ? " is-invalid" : "")
                      }
                  />
                  <ErrorMessage
                      name="telegram"
                      component="div"
                      className="invalid-feedback"
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="website">Website</label>
                  <Field
                      name="website"
                      type="text"
                      className={
                          "form-control" +
                          (errors.website && touched.website ? " is-invalid" : "")
                      }
                  />
                  <ErrorMessage
                      name="website"
                      component="div"
                      className="invalid-feedback"
                  />
                </div>
                <div className="form-group form__row">
                  <label
                      htmlFor="trafficSourcesMethodList"
                      className="signup_title"
                  >
                    Traffic Sources (Method)<span className="required-field">*</span>
                  </label>
                  <div className="form__field" id={"traffic_sources_input"}>
                    <Field
                        name="trafficSourcesMethodList"
                        type="checkbox"
                        value={"SEO"}
                        className={
                            "form-control" +
                            (errors.trafficSourcesMethodList && touched.trafficSourcesMethodList ? " is-invalid" : "")
                        }
                    />
                    <label>SEO</label>
                  </div>
                  <div className="form__field">
                    <Field
                        name="trafficSourcesMethodList"
                        type="checkbox"
                        value={"PPC"}
                        className={
                            "form-control" +
                            (errors.trafficSourcesMethodList && touched.trafficSourcesMethodList ? " is-invalid" : "")
                        }
                    />
                    <label>PPC</label>
                  </div>
                  <div className="form__field">
                    <Field
                        name="trafficSourcesMethodList"
                        type="checkbox"
                        value={"Email"}
                        className={
                            "form-control" +
                            (errors.trafficSourcesMethodList && touched.trafficSourcesMethodList ? " is-invalid" : "")
                        }
                    />
                    <label>Email</label>
                  </div>
                  <div className="form__field">
                    <Field
                        name="trafficSourcesMethodList"
                        type="checkbox"
                        value={"Other"}
                        className={
                            "form-control" +
                            (errors.trafficSourcesMethodList && touched.trafficSourcesMethodList ? " is-invalid" : "")
                        }
                    />
                    <label>Other</label>
                  </div>
                  <ErrorMessage
                      name="trafficSourcesMethodList"
                      component="div"
                      className="invalid-feedback"
                  />
                </div>
                <div className="form-group form__row">
                  <label
                      htmlFor="trafficSourcesGeoList"
                      className="signup_title"
                  >
                    Traffic Sources (GEOs)<span className="required-field">*</span>
                  </label>
                  <div className="form__field">
                    <Field
                        name="trafficSourcesGeoList"
                        type="checkbox"
                        value={"UK"}
                        className={
                            "form-control" +
                            (errors.trafficSourcesGeoList && touched.trafficSourcesGeoList ? " is-invalid" : "")
                        }
                    />
                    <label>UK</label>
                  </div>
                  <div className="form__field">
                    <Field
                        name="trafficSourcesGeoList"
                        type="checkbox"
                        value={"DE"}
                        className={
                            "form-control" +
                            (errors.trafficSourcesGeoList && touched.trafficSourcesGeoList ? " is-invalid" : "")
                        }
                    />
                    <label>DE</label>
                  </div>
                  <div className="form__field">
                    <Field
                        name="trafficSourcesGeoList"
                        type="checkbox"
                        value={"Other"}
                        className={
                            "form-control" +
                            (errors.trafficSourcesGeoList && touched.trafficSourcesGeoList ? " is-invalid" : "")
                        }
                    />
                    <label>Other</label>
                  </div>
                  <ErrorMessage
                      name="trafficSourcesGeoList"
                      component="div"
                      className="invalid-feedback"
                  />
                </div>
                <div className="form-group form__row">
                  <label
                      htmlFor="estimatedMonthlyFtdsRange"
                      className="signup_title"
                  >
                    Estimated monthly FTDs<span className="required-field">*</span>
                  </label>
                  <div className="form__field">
                    <Field
                        name="estimatedMonthlyFtdsRange"
                        type="radio"
                        required
                        value="ZERO_TO_FIFTY"
                        className={
                            "form-control" +
                            (errors.estimatedMonthlyFtdsRange && touched.estimatedMonthlyFtdsRange ? " is-invalid" : "")
                        }
                    />
                    <label>0-50</label>
                  </div>
                  <div className="form__field">
                    <Field
                        name="estimatedMonthlyFtdsRange"
                        type="radio"
                        required
                        value="FIFTY_ONE_TO_ONE_HUNDRED"
                        className={
                            "form-control" +
                            (errors.estimatedMonthlyFtdsRange && touched.estimatedMonthlyFtdsRange ? " is-invalid" : "")
                        }
                    />
                    <label>51-100</label>
                  </div>
                  <div className="form__field">
                    <Field
                        name="estimatedMonthlyFtdsRange"
                        type="radio"
                        required
                        value="ONE_HUNDRED_AND_ONE_TO_TWO_HUNDRED"
                        className={
                            "form-control" +
                            (errors.estimatedMonthlyFtdsRange && touched.estimatedMonthlyFtdsRange ? " is-invalid" : "")
                        }
                    />
                    <label>101-200</label>
                  </div>
                  <div className="form__field">
                    <Field
                        name="estimatedMonthlyFtdsRange"
                        type="radio"
                        required
                        value="TWO_HUNDRED_AND_ONE_TO_THREE_HUNDRED"
                        className={
                            "form-control" +
                            (errors.estimatedMonthlyFtdsRange && touched.estimatedMonthlyFtdsRange ? " is-invalid" : "")
                        }
                    />
                    <label>201-300</label>
                  </div>
                  <div className="form__field">
                    <Field
                        name="estimatedMonthlyFtdsRange"
                        type="radio"
                        required
                        value="THREE_HUNDRED_PLUS"
                        className={
                            "form-control" +
                            (errors.estimatedMonthlyFtdsRange && touched.estimatedMonthlyFtdsRange ? " is-invalid" : "")
                        }
                    />
                    <label>300+</label>
                  </div>
                  <ErrorMessage
                      name="estimatedMonthlyFtdsRange"
                      component="div"
                      className="invalid-feedback"
                  />
                </div>
                <div className="form-group form__row">
                  <label htmlFor="proposedDealType" className="signup_title">
                    Proposed Deal Type<span className="required-field">*</span>
                  </label>
                  <div className="form__field">
                    <Field
                        name="proposedDealType"
                        type="radio"
                        required
                        value="REV_SHARE"
                        className={
                            "form-control" +
                            (errors.proposedDealType && touched.proposedDealType ? " is-invalid" : "")
                        }
                    />
                    <label>Revshare</label>
                  </div>
                  <div className="form__field">
                    <Field
                        name="proposedDealType"
                        type="radio"
                        required
                        value="CPA"
                        className={
                            "form-control" +
                            (errors.proposedDealType && touched.proposedDealType ? " is-invalid" : "")
                        }
                    />
                    <label>CPA</label>
                  </div>
                  <div className="form__field">
                    <Field
                        name="proposedDealType"
                        type="radio"
                        required
                        value="HYBRID"
                        className={
                            "form-control" +
                            (errors.proposedDealType && touched.proposedDealType ? " is-invalid" : "")
                        }
                    />
                    <label>Hybrid</label>
                  </div>
                  <ErrorMessage
                      name="proposedDealType"
                      component="div"
                      className="invalid-feedback"
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="message">Message</label><span className="required-field">*</span>
                  <Field
                      name="message"
                      type="text"
                      className={
                          "form-control" +
                          (errors.message && touched.message ? " is-invalid" : "")
                      }
                  />
                </div>
                <div
                    className="form-group"
                    style={{display: "flex", justifyContent: "center"}}
                >
                  <ReCAPTCHA
                      ref={reCaptchaRef}
                      sitekey="6Lc6O9AUAAAAAF6myYURrvZwW43E4ShPeKOVKCHe"
                      onChange={handleCaptchaChange}
                  />
                  {erCaptcha && (
                      <Alert severity="error">ReCaptcha is required</Alert>
                  )}
                </div>
                <div className="form-group" style={{textAlign: "center"}}>
                  <Button
                      size="large"
                      variant="contained"
                      type="submit"
                      color="primary"
                  >
                    Sign Up
                  </Button>
                </div>
              </Form>
          )}
        </Formik>
      )}
    </Container>
  );
};
